<script>
  export let src;
  export let page = false;
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import snarkdown from "snarkdown";
  let textWrapper;

  const snarkdownEnhanced = (md) => {
    const htmls = md
      .split(/(?:\r?\n){2,}/)
      .map((l) =>
        [" ", "\t", "#", "-", "*", "<"].some((ch) => l.startsWith(ch))
          ? snarkdown(l)
          : `<p>${snarkdown(l)}</p>`
      );

    return htmls.join("\n\n");
  };

  const transformText = async (src) => {
    let markdown = await fetch(`assets/texts/${src}.md`).then((res) =>
      res.text()
    );
    let html = snarkdownEnhanced(markdown);
    textWrapper.innerHTML = html;
  };

  const linkFootnotes = (text) => {
    let fn = text.querySelector(".footnotes");
    if (!fn) return;

    let sups = text.querySelectorAll("sup");
    let refs = fn.querySelectorAll("li");

    [...sups].forEach((s, i) => {
      s.innerHTML = `<a href="#fn${i + 1}">${s.textContent}</a>`;
    });

    [...refs].forEach((r, i) => {
      r.id = `fn${i + 1}`;
    });
  };

  const targetBlank = (text) => {
    let links = [...text.querySelectorAll("a")];
    links.forEach((l) => {
      l.target = "_blank";
      l.rel = "noopener";
    });
  };

  onMount(() => {
    if (src) {
      transformText(src).then(() => {
        linkFootnotes(textWrapper);
        targetBlank(textWrapper);
      });
    }
  });
</script>

<style>
  .textWrapper {
    margin: 0 auto 10vh;
    padding: 0 calc(calc(100vw - 800px) / 2) 10vh;
    height: 84vh;
    overflow-y: scroll;
    position: absolute;
    top: 0;
    width: 100vw;
    background-color: white;
  }
  .close {
    border: none;
    font-size: 3rem;
    background-color: white;
    padding: 0;
    line-height: 0;
    position: fixed;
    top: calc(50vh - 1.5rem);
    left: 2vw;
    z-index: 100;
  }
  .close:hover {
    color: darkslategrey;
    cursor: pointer;
  }
  :global(.textWrapper img) {
    max-width: 100%;
    margin: 60px 0;
  }
  :global(.textWrapper .logos img) {
    width: calc(100% / 4);
    filter: grayscale(1);
    margin: 20px;
  }

  :global(.end) {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    height: 10px;
  }
  :global(blockquote) {
    font-family: "linotype-sabon";
  }

  :global(.footnotes li) {
    font-family: "linotype-sabon";
    line-height: 1.5;
  }

  :global(.textWrapper h3) {
    margin-bottom: 0;
  }

  @media (max-width: 800px) {
    .textWrapper {
      padding: 10vw 2vw 5vw;
      width: 96vw;
    }
    :global(.footnotes) {
      max-width: 90vw;
      overflow-x: scroll;
    }
  }
  @media (max-width: 920px) {
    .close {
      display: none;
    }
  }

  @supports (display: grid) {
    @media (min-width: 960px) {
      .textWrapper {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        margin: 0 20px 40px;
        grid-column-gap: 20px;
        padding: 0;
        width: calc(100vw - 60px);
      }
      :global(.textWrapper > *) {
        grid-column: 4 / 10;
      }
      :global(.textWrapper > picture.left) {
        grid-column: 1 / 7;
      }
      :global(.textWrapper > picture.right) {
        grid-column: 7 / 13;
      }
      :global(.textWrapper > picture.lq) {
        grid-column: 3 / 8;
      }
      :global(.textWrapper > picture.rq) {
        grid-column: 6 / 11;
      }
      :global(.textWrapper > picture.dh) {
        grid-row: auto / span 2;
      }
      :global(.textWrapper > picture.fw) {
        grid-column: 1 / 13;
      }
      :global(.textWrapper > picture.dh + picture.left img) {
        margin: 0;
      }
    }
  }
</style>

{#if !page}<button class="close" on:click>&laquo;</button>{/if}
<div
  in:fade={{ delay: 250, duration: 300 }}
  out:fade={{ delay: 0, duration: 300 }}
  class="textWrapper"
  bind:this={textWrapper} />
