<script>
  export let activeTabValue = false;
  export let header = false,
    texts = false;

  export let items = [];

  const handleClick = (tabValue) => () => {
    activeTabValue = tabValue;
    history.pushState({ page: tabValue }, activeTabValue, `?${activeTabValue}`);
  };
</script>

<style>
  .tab-menu {
    list-style: none;
    padding-left: 0;
  }
  .tab-menu.texts {
    max-width: 400px;
    margin: 2rem auto;
    text-align: center;
  }
  li {
    margin: 1.5rem 0;
  }
  li:before {
    content: "\00BB";
    padding-right: 20px;
    opacity: 0;
  }
  li:hover {
    cursor: pointer;
    color: darkslategrey;
  }
  li:hover:before {
    opacity: 1;
  }
  :global(.tab-menu.texts em) {
    padding-left: 30px;
  }
  @media (max-width: 800px) {
    li:before {
      padding-right: 10px;
    }
    :global(.tab-menu.texts em) {
      padding-left: 20px;
    }
  }
  @media (max-width: 500px) {
    li:before {
      padding-right: 4px;
    }
    :global(.tab-menu.texts em) {
      padding-left: 12px;
    }
  }
</style>

<ul class="tab-menu" class:header class:texts>
  {#if Array.isArray(items)}
    {#each items as item}
      <li class={activeTabValue === item.value ? 'active' : ''}>
        <span
          on:click={handleClick(item.value)}
          on:click>{@html item.label}</span>
      </li>
    {/each}
  {/if}
</ul>
