<script>
  import "./components/AudioPlayer.svelte";
  import AudioPlayer from "./components/AudioPlayer.svelte";
  import Slideshow from "./components/Slideshow.svelte";
  import TextWrapper from "./components/TextWrapper.svelte";
  import Tabs from "./components/Tabs.svelte";
  let currentTab,
    navTab,
    path,
    home = false;

  window.addEventListener("popstate", (e) => {
    router();
  });

  const router = () => {
    let loc = window.location.search;
    if (!loc) {
      currentTab = null;
      home = true;
      navTab = "home";
      return;
    }
    path = loc.slice(1);
    let navMatch = navItems.find((r) => r.value === path);
    if (navMatch) {
      currentTab = null;
      navTab = navMatch.value;
    } else {
      let tabMatch = tabItems.find((r) => r.value === path);
      if (tabMatch) {
        currentTab = tabMatch.value;
      }
    }
  };

  const handleHome = (e) => {
    if (e.target.href === `${window.location.origin}/`) {
      currentTab = null;
      home = true;
      navTab = "home";
      history.pushState({ page: navTab }, navTab, "/");
    }
  };

  const handleClose = () => {
    currentTab = null;
    history.pushState({ page: navTab }, navTab, `?${navTab}`);
  };

  let navItems = [
    { value: "home", label: "Home" },
    { value: "about", label: "About" },
    { value: "texts", label: "Texts" },
  ];

  let tabItems = [
    {
      value: "christopher_steenson_on_returning",
      label: "Christopher Steenson<br><em>On Returning</em>",
    },
    {
      value: "suzanne_walsh_paradise_isle",
      label: "Suzanne Walsh<br><em>Paradise Isle</em>",
    },
    {
      value: "mark_garry_resonant_landscapes",
      label: "Mark Garry<br><em>Resonant Landscapes</em>",
    },
    {
      value: "jez_riley_sound_culture",
      label:
        "Jez riley French<br><em>Listening > Sound Art > Cinematic Distortion</em>",
    },
  ];

  router();
</script>

<style>
  :global(*, *:before, *:after) {
    box-sizing: border-box;
  }
  :global(body) {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 300;
    max-width: 100vw;
    overflow-x: hidden;
  }

  :global(h1) {
    font-weight: 600;
    font-size: 3rem;
    font-style: italic;
    margin: 0;
  }

  :global(h1 a) {
    color: black !important;
    text-decoration: none !important;
  }
  :global(h1 a:hover) {
    color: darkslategrey !important;
    text-decoration: none !important;
  }

  :global(.textWrapper h1) {
    margin-top: 8vh;
  }

  :global(h2) {
    font-size: 2rem;
    margin: 0.8rem 0 2rem;
    font-family: "linotype-sabon", Georgia, "Times New Roman", Times, serif;
  }

  :global(p, li) {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.45;
  }
  :global(p) {
    font-size: 19px;
    line-height: 1.5;
    font-family: "linotype-sabon", Georgia, "Times New Roman", Times, serif;
  }
  :global(blockquote) {
    line-height: 1.5;
    margin: 0 40px;
  }
  :global(sup) {
    font-size: 0.6rem;
  }

  main {
    display: flex;
    width: 100vw;
    flex-direction: column;
  }

  header {
    display: flex;
    width: 100vw;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
  }

  :global(ul.header) {
    display: flex;
    list-style: none;
    padding-left: 0;
  }

  :global(ul.header > li) {
    margin: 0 2rem;
    font-weight: 600;
  }

  .column {
    padding: 4vh 10px 0;
    height: 80vh;
    position: relative;
  }

  .column {
    width: 100vw;
  }

  :global(a) {
    color: black;
    text-decoration: none;
  }

  :global(a:hover) {
    color: darkslategrey;
  }

  :global(.bio) {
    background-color: #eaebeb;
    padding: 10px 20px;
    margin-bottom: 100px;
  }

  @media (max-width: 800px) {
    header {
      padding: 0.8rem 1.2rem;
    }
    h1 {
      font-size: 1.5rem;
    }
    :global(h1) {
      font-size: 1.8rem;
    }
    :global(h2) {
      font-size: 1.4rem;
    }
    :global(p, li) {
      font-size: 1.05rem;
      font-weight: 400;
      line-height: 1.45;
    }
    :global(ul.header) {
      margin: 0;
    }
    :global(ul.header li) {
      margin: 0 1rem;
    }
  }
  @media (max-width: 500px) {
    h1 {
      font-size: 1.2rem;
    }
    :global(ul.header li) {
      margin: 0 0.5rem;
      font-size: 1rem;
    }
    .column {
      padding: 2vw;
      max-width: 100vw;
      margin: 0;
    }
  }
</style>

<main>
  <header>
    <h1><a href="/" on:click|preventDefault={handleHome}>On Chorus</a></h1>
    <Tabs
      bind:activeTabValue={navTab}
      on:click={handleClose}
      items={navItems.slice(1)}
      header />
  </header>

  <div class="column">
    {#if navTab === navItems[0].value && home}
      <Slideshow timeout={10} />
    {/if}

    {#if navTab === navItems[1].value}
      <TextWrapper src={navItems[1].value} page />
    {/if}

    {#if navTab === navItems[2].value}
      <Tabs bind:activeTabValue={currentTab} items={tabItems} texts />
    {/if}

    {#if currentTab === tabItems[0].value}
      <TextWrapper src={tabItems[0].value} on:click={handleClose} />
    {/if}
    {#if currentTab === tabItems[1].value}
      <TextWrapper src={tabItems[1].value} on:click={handleClose} />
    {/if}
    {#if currentTab === tabItems[2].value}
      <TextWrapper src={tabItems[2].value} on:click={handleClose} />
    {/if}
    {#if currentTab === tabItems[3].value}
      <TextWrapper src={tabItems[3].value} on:click={handleClose} />
    {/if}
  </div>
  <AudioPlayer src="https://fallowmedia.com/2020/nov/on-chorus/on-chorus" />
</main>
