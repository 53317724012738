<script>
  export let image, alt;
  export let visible = false;
</script>

<style>
  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
  picture {
    opacity: 0;
    transition: all 0.6s ease-in;
    position: absolute;
    left: 10vw;
    width: 80vw;
    max-height: 74vh;
    display: flex;
  }
  .visible {
    opacity: 1 !important;
  }
  @media (max-width: 960px) {
    picture {
      left: 0;
      width: 96vw;
    }
  }
</style>

<picture class:visible>
  <source
    data-srcset="/assets/static/oc-images/m/ChristopherSteenson_OnChorus-{image}.jpg 960w"
    type="image/jpeg" />
  <source
    data-srcset="/assets/static/oc-images/m/ChristopherSteenson_OnChorus-{image}.webp 960w"
    type="image/webp" />
  <source
    data-srcset="/assets/static/oc-images/s/ChristopherSteenson_OnChorus-{image}.webp"
    type="image/webp" />
  <img
    loading="lazy"
    data-src="/assets/static/oc-images/s/ChristopherSteenson_OnChorus-{image}.jpg"
    {alt}
    sizes="50vw" />
</picture>
