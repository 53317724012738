<script>
  export let src;

  let audioElement,
    currentTime,
    muted = true,
    paused = true,
    played = false;

  const getTime = () => {
    let d = new Date();
    let m = d.getMinutes();
    let s = d.getSeconds();
    currentTime = m * 60 + s;
  };

  const checkTime = () => {
    let d = new Date();
    let h = d.getHours();
    if (h === 8) {
      getTime();
      return true;
    } else {
      return false;
    }
  };

  const formatTime = (time) => {
    function pad(n, width, z) {
      z = z || "0";
      n = n + "";
      return n.length >= width
        ? n
        : new Array(width - n.length + 1).join(z) + n;
    }
    let mm = pad(parseInt(time / 60), 2);
    let ss = pad(parseInt(time % 60), 2);
    return `${mm}:${ss}`;
  };

  const handleUnmute = (e) => {
    if (!played) {
      getTime();
      paused = false;
      played = true;
    }
    muted = !muted;
  };
</script>

<style>
  #audioPlayer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .controls {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
  }

  .audiotime {
    display: flex;
    align-items: center;
    margin: 5px 0 10px;
  }

  .elapsed {
    font-size: 0.7rem;
    font-variant-numeric: tabular-nums;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  .listen {
    font-size: 1.2rem;
    font-style: italic;
  }
  .listen:hover {
    color: darkslategrey;
    cursor: pointer;
  }
  .listen svg {
    fill: rgb(107, 107, 107);
  }
  .listen svg:hover {
    fill: darkslategrey;
  }
  .listen svg g.muted {
    opacity: 0;
  }

  svg.disabled {
    fill: rgb(209, 209, 209);
    margin-bottom: 10px;
  }
</style>

<div id="audioPlayer">
  {#if checkTime()}
    <!-- svelte-ignore a11y-media-has-caption -->
    <audio
      id="a"
      {src}
      bind:muted
      bind:currentTime
      bind:paused
      bind:this={audioElement} />
    <div class="controls">
      <span class="listen" on:click={handleUnmute}>
        <svg
          width="70"
          height="30"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 274.1 237.5">
          <g id="lines" class:muted>
            <path
              d="M212.2,26.7c4.6-4.9,8.8-9.3,13-13.8c56.5,49,71.5,155.8,4.2,224.6c-4.3-4.5-8.6-9-13.4-14c25.5-27.2,38.9-60.4,38.4-98.4 C253.9,87,239.9,54,212.2,26.7z" />
            <path
              d="M189.7,200.7c-4.8-4.5-9.3-8.9-14.2-13.5c16.5-17.5,24.9-38.2,24.5-62.1c-0.3-24-9.4-44.4-26.3-61.5 c4.7-4.4,9.2-8.8,13.8-13.2C223.6,81.2,236,152.6,189.7,200.7z" />
          </g>
          <path
            d="M155.9,235.2c-30.6-25.5-60.6-50.4-90.8-75.3c-1.5-1.2-3.9-1.7-5.9-1.7c-19.6-0.1-39.3-0.1-59.2-0.1	c0-26.1,0-52,0-78.3c1.3-0.1,2.8-0.2,4.2-0.2c18,0,36,0.1,54-0.1c2.2,0,4.9-0.9,6.6-2.3C87,58.6,109,39.8,131,21.1	c8.1-6.9,16.2-13.7,24.9-21.1C155.9,78.7,155.9,156.6,155.9,235.2z" /></svg>
      </span>
      <div class="audiotime">
        <span
          class="elapsed">{currentTime ? formatTime(currentTime) : '00:00'}</span>
      </div>
    </div>
  {:else}
    <svg
      width="70"
      height="30"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 274.1 237.5"
      class="disabled">
      <g id="lines" class="muted">
        <path
          d="M212.2,26.7c4.6-4.9,8.8-9.3,13-13.8c56.5,49,71.5,155.8,4.2,224.6c-4.3-4.5-8.6-9-13.4-14c25.5-27.2,38.9-60.4,38.4-98.4 C253.9,87,239.9,54,212.2,26.7z" />
        <path
          d="M189.7,200.7c-4.8-4.5-9.3-8.9-14.2-13.5c16.5-17.5,24.9-38.2,24.5-62.1c-0.3-24-9.4-44.4-26.3-61.5 c4.7-4.4,9.2-8.8,13.8-13.2C223.6,81.2,236,152.6,189.7,200.7z" />
      </g>
      <path
        d="M155.9,235.2c-30.6-25.5-60.6-50.4-90.8-75.3c-1.5-1.2-3.9-1.7-5.9-1.7c-19.6-0.1-39.3-0.1-59.2-0.1	c0-26.1,0-52,0-78.3c1.3-0.1,2.8-0.2,4.2-0.2c18,0,36,0.1,54-0.1c2.2,0,4.9-0.9,6.6-2.3C87,58.6,109,39.8,131,21.1	c8.1-6.9,16.2-13.7,24.9-21.1C155.9,78.7,155.9,156.6,155.9,235.2z" /></svg>
    <span class="elapsed">The next birdsong broadcast will begin on this site
      between 8am and 9am.</span>
  {/if}
</div>
