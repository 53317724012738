<script>
  import ResponsiveImage from "./ResponsiveImage.svelte";
  import { fade } from "svelte/transition";

  export let timeout;

  let slideshow;

  let photos = [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 23, 22, 24];

  let lazyload = (picture, show = true) => {
    let srcsets = [...picture.querySelectorAll("source")];
    let img = picture.querySelector("img");
    if (!img.src) {
      srcsets.forEach((s) => {
        s.srcset = s.dataset.srcset;
      });
      img.src = img.dataset.src;
    }

    // Add visible class to next image
    if (show) {
      picture.classList.add("visible");
    }
  };

  let animate = (images, i) => {
    // Remove visible class from all images
    [...images].forEach((p) => {
      p.classList.remove("visible");
    });

    // Lazy load the next image
    lazyload([...images][i]);

    // Lazy load the following image, but don't add the visible class. (Helps with slow connections.)
    if (i !== [...images].length - 1) {
      lazyload([...images][i + 1], false);
    }

    // Animate
    setTimeout(() => {
      if (i === [...images].length - 1) {
        animate(images, 0);
      } else {
        animate(images, i + 1);
      }
    }, timeout * 1000);
  };

  setTimeout(() => {
    let pictures = slideshow.querySelectorAll("picture");
    animate(pictures, 0);
  }, 10);
</script>

<style>
  .slideshow {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    height: 74vh;
    position: absolute;
  }
  @media (max-width: 960px) {
    .slideshow {
      align-items: center;
    }
  }
</style>

<div
  in:fade={{ delay: 350, duration: 400 }}
  out:fade={{ delay: 0, duration: 400 }}
  class="slideshow"
  bind:this={slideshow}>
  {#each photos as photo, i}
    <ResponsiveImage image={photo} alt="slideshow-image-{i}" />
  {/each}
</div>
